function ready(fn) {
  if (document.readyState != 'loading'){
    fn();
  } else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', fn);
  } else {
    document.attachEvent('onreadystatechange', function() {
      if (document.readyState != 'loading')
        fn();
    });
  }
}

var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)

ready(function() {
  var svgs = document.querySelectorAll('.svg-illustration')
  for(i=0;i<svgs.length;i++) {
    var svg = svgs[i];
    var fullImageUrl = svg.getAttribute('data-image');
    var naturalWidth = svg.getAttribute('data-width');

    if (viewportWidth > 500) {
      var imageTag = svg.querySelector('image');
      imageTag.setAttribute("xlink:href",fullImageUrl);

      // Replace the header background with a hi-res version of the first image
      if (i==0) {
        setTimeout(function() {
          var headerImage =document.getElementById('article_background_image');
          // Lock the width and height to prevent it going huuuge
          /*
          headerImage.setAttribute('width', headerImage.offsetWidth);
          headerImage.setAttribute('height', headerImage.offsetHeight);
          headerImage.setAttribute('src', this);
          */
        }.bind(fullImageUrl),500);
      }
    }
  }
});
