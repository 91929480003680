import React from 'react'
import axios from 'axios';

 function post(path, params, method) {
    method = method || "post"; // Set method to post by default if not specified.

    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.
    var form = document.createElement("form");
    form.setAttribute("method", method);
    form.setAttribute("action", path);

    for(var key in params) {
        if(params.hasOwnProperty(key)) {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", params[key]);

            form.appendChild(hiddenField);
         }
    }

    document.body.appendChild(form);
    form.submit();
}


class EmailInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: props.email,
      isLoading: false,
      isSet: props.email !== '',
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    const email = this._emailInput.value;

    var re = /\S+@\S+\.\S+/;
    if(!re.test(email)) {
      alert("That is not a valid email address")
      return;
    }

    // Check the account status
    this.setState({
      isLoading: true,
    })


    const operation = this.props.platform == 'iOS' ? 'ios.create_password' : 'web.create_password';

    axios.put(API_URL+'/auth/status', { 
      email: email,
      operation,
    })
      .then( (response) => {
        this.props.onSet({
          email: email,
          accountStatus: response.data.data.status,
        })
        this.setState({
          isLoading: false,
          isSet: true,
        })
      })
  }

  handleEmailChange(e) {
    this.setState({
      isSet: false,
    })
    this.props.onChange();
  }

  render() {
    let { isLoading, isSet, email } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label htmlFor="email" className="sr-only">Email address</label>
          <input type="email" placeholder="Email address" ref={ el => this._emailInput = el }  onChange={this.handleEmailChange} name="email" className="form-control"/>
        </div>

        {isLoading && 
          <p>Loading...</p>}
        {!isLoading && !isSet && 
          <div className="form-actions">
          <input type="submit" className="btn btn-primary" value="Next" />
          </div>}
      </form> 
    );
  }
}

class LoginForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      password: null,
      isLoading: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    const { email } = this.props;
    const password = this._passwordInput.value;

    axios.post(API_URL+'/auth/login', {
      email, 
      password,
      set_session: true,
    }).then( response => {
      const data = { email, password, token: response.data.data.token }
      this.props.onComplete(data)
    }).catch( error => {
      console.error(error);
      const data = error.response.data;
      if(data.meta.status == 401) {
        alert(data.errors.join(' ')) 
      }
    });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} method="post">
        <div className="form-group">
          <label>Enter your password to login</label>
          <input type="password" name="password" ref={ el => this._passwordInput = el } className="form-control" />

        </div>

        <div className="form-actions">
          <input type="submit" className="btn btn-primary" value="Login" />
        </div>

        <div style={{marginTop: '2rem'}}>
          <a href="/forgotten-password">Forgotten password?</a>
        </div>
      </form>
    );
  }
}

class SetPasswordForm extends React.Component {
  render() {
    const { email } = this.props;

    return (
      <div>
        <h2>You need to choose a password</h2>
        <p>We have sent an email to {email}. Click the link
           in that email to choose a password for your account.</p> 
      </div>
    )
  }
}

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    
    const nameParts = this._nameInput.value.split(' ');
    var firstName = nameParts.slice(0, -1).join(' ');
    var lastName = nameParts.slice(-1).join(' ');
    if(firstName == '') { 
      firstName = lastName; 
      lastName = ''; 
    }
    
    const data = {
      email: this.props.email,
      first_name: firstName,
      last_name: lastName,
      password: this._passwordInput.value,
      country_code: this._countryInput._input.value,
      country_is_guess: false,
      source: 'Website',
      set_session: true,
    }


    axios.post(API_URL+"/account", data)
      .then( response => {
        this.props.onComplete( { email: data.email, password: data.password, token: response.data.data.token } )
      })
  }



  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Your name</label>
          <input type="textfield" name="name" className="form-control"  ref={ el => this._nameInput = el }  required />
        </div>

        <div className="form-group">
          <label htmlFor="password">Choose a password</label>
          <input type="password" name="password" className="form-control" required ref={ el => this._passwordInput = el } />
        </div>

        <div className="form-group">
          <label htmlFor="country_code">Where do you live?</label>
          <CountrySelect className="form-control" name="country_code" ref={ el => this._countryInput = el } />
        </div>

        <div className="form-actions">
          <input type="submit" className="btn btn-primary" value="Register" />
        </div>
      </form>
    );
  }
}

class CountrySelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      countries: [
        {
          code: 'US',
          name: 'United States',
        },
        {
          code: 'GB',
          name: 'United Kingdom',
        },
      ]
    }
  }

  componentWillMount() {
    axios.get(API_URL+'/countries')
     .then( response  => {

       const countries = response.data.data;
       const meta = response.data.meta;

       let countryCode = meta.ip_country !== null ? meta.ip_country.code : null;
       this.setState({
         countries: countries,
         defaultValue: countryCode,
       });
     }) 
  }

  render() {
    const { countries, defaultValue } = this.state;
    return (
      <select {...this.props} defaultValue={defaultValue} ref={ el => this._input = el}>
        {countries.map(country => 
          <option value={country.code} key={country.code}>{country.name}</option>    
        )}
      </select>
    )
  }
}

class LoginBox extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
      isLoading: false,
      accountStatus: null,
      email: '',
    }

    this.onEmailChange = this.onEmailChange.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.handleLoginComplete = this.handleLoginComplete.bind(this);
  }

  render() {
    const { email } = this.state;
    
    return (
      <div>    
        <EmailInput onSet={this.setEmail} onChange={this.onEmailChange} email={email} platform={this.props.platform} />
        {this.renderForm()} 
      </div>
    )
  }

  renderForm() {

    const { email, accountStatus } = this.state;
    if(accountStatus == null) return;

    if(accountStatus == 'has_password') {
      return <LoginForm email={email} onComplete={this.handleLoginComplete} />
    } else if (accountStatus == 'no_password') {
      return <SetPasswordForm email={email} onComplete={this.handleLoginComplete} />;
    } else {
      return <RegisterForm email={email} onComplete={this.handleLoginComplete} />
    }
  }

  onEmailChange() {
    this.setState({
      accountStatus: null,
    })
  }

  setEmail(email, accountStatus) {
    this.setState(
      email,
      accountStatus, 
    )
  }

  handleLoginComplete(userData) {


    if(this.props.platform == 'iOS') {
      const redirectTo =  "hacw://action/login?token="+userData.token;
      window.location = redirectTo;
    } else if(this.props.platform == 'Android') {
      appLink("action/login?token="+userData.token);
    } else {
      post('/login', { email: userData.email, password: userData.password });
    }
  }
}




export default LoginBox
