/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


import React from 'react';
import ReactDOM from 'react-dom';
import 'lazysizes';
import 'application/responsive_illustrations';

import LoginBox from 'application/components/LoginBox';

import 'bootstrap.native/dist/bootstrap-native-v4';


window.trackEvent = function() {
  return true;
}

function createCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name,"",-1);
}


var loginBoxEl = document.getElementById('login_box');
if(loginBoxEl) {
  ReactDOM.render(
    React.createElement(LoginBox, JSON.parse(loginBoxEl.getAttribute('data-react-props')), null),
    loginBoxEl
  );
}


// Trailer sticky
(function() {
  var stickyEl = document.getElementById("course_sticky");
  if(!stickyEl) return;


  if(window.innerWidth < 600) return;


  var cookie = readCookie('hideCourseSticky');
  if(!cookie) {
    stickyEl.style.display = 'block';
  }

  var closeEl = stickyEl.querySelector('._close');

  closeEl.addEventListener('click', function(e) {
    e.preventDefault();
    createCookie('hideCourseSticky',true);

    // REmove the iframe to stop playing
    stickyEl.removeChild(document.getElementById("course_sticky_video"));

    stickyEl.style.display = 'none';
  })
})();


import SmartBanner from 'smart-app-banner';

if(!window.hideMobileBanner) {
  new SmartBanner({
    daysHidden: 15,   // days to hide banner after close button is clicked (defaults to 15)
    daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
    appStoreLanguage: 'us', // language code for the App Store (defaults to user's browser language)
    title: 'How a Car Works',
    author: 'The complete app',
    button: 'VIEW',
    store: {
        ios: 'On the App Store',
        android: 'In Google Play',
    },
    price: {
        ios: 'FREE',
        android: 'FREE',
    }
    //force: 'android' // Uncomment for platform emulation
  });
}


/**
 * Main navigation ------------------------------------
 */
(function() {
  const mobileTriggerEl = document.getElementById('mobile-nav-button');
  const mainNavEl = document.getElementById('main-nav');
  const mobileActionsEl = document.getElementById('mobile-actions');

  // If we don't have a menu on this page, skip the initialization
  if(!mobileTriggerEl) return;

  mobileTriggerEl.addEventListener('click', () => {
    mainNavEl.classList.toggle('open');
    mobileActionsEl.classList.toggle('nav-is-open');
  });

  const searchTriggerEl = document.getElementById('search-opener');
  const searchEl = document.getElementById('search-area');

  searchTriggerEl.addEventListener('click', () => {
    searchEl.classList.toggle('open');
    searchTriggerEl.classList.toggle('search-is-open');
  });

  if ("IntersectionObserver" in window 
    && "IntersectionObserverEntry" in window 
    && "intersectionRatio" in window.IntersectionObserverEntry.prototype) {
      new IntersectionObserver(function(t) {
        t[0].boundingClientRect.y < 0 ? document.body.classList.add("header-not-at-top") 
          : document.body.classList.remove("header-not-at-top");
      }).observe(document.getElementById("top-of-site-pixel-anchor"));
  }
})();
